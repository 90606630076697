import React from 'react'

import './styles.scss'

const ImagePopup = ({image, close}) => {
  return (
    <div className="image-popup">
      <button className="image-popup__close" onClick={close}>
        Закрыть
      </button>
      <div className="image-popup__wrapper">{image && <img src={image} />}</div>
    </div>
  )
}

export default ImagePopup
